import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import dog from '../assets/images/hond.gif';
import Nav from '../components/Nav';
import Typography from '../styles/Typography';
import GlobalStyles from '../styles/GlobalStyles';

const Bodystyles = styled.div`
  .grid-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    padding-right: 30px;
  }

  #buttonchange
  {
    margin: 20px auto 30px auto;
    width: 300px;
    justify-content: center;
  }
  
  p
  {
    line-height: 35px;
    color: #14284e;
    font-size: 16px; 
    text-align: center;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .titel {
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    text-align: center;
    margin-top: 150px;
    width: 100vw;
  }

  img
  {
    margin: 20px auto 30px auto;
    height: 350px;
    width: auto;
  }

  a
  {
      margin: auto;
  }

  @media (max-width: 500px)
  {
    h1
    {
      font-size: 50px !important;
    }
  }
`;

const Contact = () => (
  <>
    <Nav />
    <Typography />
    <GlobalStyles />
    <Bodystyles>
      <div className="main">
        <h1 className="titel">Bedankt!</h1>
        <p>
          Bedankt voor het verzenden van uw bericht!
        </p>
        <img src={dog} alt="thankyoudog" />
        <Link to="/">
          <button type="button" id="buttonchange" className="orangebutton">
            Terug naar de hoofdpagina
          </button>
        </Link>
      </div>
    </Bodystyles>
  </>
);

export default Contact;
